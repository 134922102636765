var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full container flex flex-col items-center justify-between text-gray-700"},[_c('div',{staticClass:"mb-10 w-full"},[_c('div',{staticClass:"md:grid md:grid-cols-1 md:gap-2"},[_vm._m(0),_c('div',{staticClass:"col-span-2"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.success)?_c('div',{staticClass:"w-full flex justify-end items-center"},[_c('div',{staticClass:"mb-2 w-full max-w-sm overflow-hidden rounded-lg bg-white border border-gray-200"},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-shrink-0"},[_c('svg',{staticClass:"h-6 w-6 text-green-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]),_c('div',{staticClass:"ml-3 w-0 flex-1 pt-0.5"},[_c('p',{staticClass:"text-sm font-medium text-gray-900"},[_vm._v(" Paciente actualizado! ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" El paciente tiene nuevos datos de contacto. ")])]),_c('div',{staticClass:"ml-4 flex flex-shrink-0"},[_c('button',{staticClass:"inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"},on:{"click":function () {
                          _vm.success = '';
                        }}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close")]),_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"}})])])])])])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.error)?_c('div',{staticClass:"w-full flex justify-end items-center"},[_c('div',{staticClass:"mb-2 w-full max-w-sm overflow-hidden rounded-lg bg-white border border-gray-200"},[_c('div',{staticClass:"p-4"},[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex-shrink-0"},[_c('svg',{staticClass:"h-6 w-6 text-red-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]),_c('div',{staticClass:"ml-3 w-0 flex-1 pt-0.5"},[_c('p',{staticClass:"text-sm font-medium text-gray-900"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]),_c('div',{staticClass:"ml-4 flex flex-shrink-0"},[_c('button',{staticClass:"inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"},on:{"click":function () {
                          _vm.error = '';
                        }}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close")]),_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"}})])])])])])])]):_vm._e()])],1),_c('div',{staticClass:"mt-5 md:mt-0 overflow-hidden md:col-span-2 rounded-md border border-gray-200"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"bg-white p-5 overflow-hidden"},[_c('div',{staticClass:"col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"},[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"marital_status"}},[_vm._v("Estado Civil")]),_c('div',{staticClass:"mt-1 w-full"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.marital_status),expression:"form.marital_status"}],staticClass:"block border w-full rounded-md shadow-sm sm:text-sm",class:_vm.submitted && _vm.$v.form.marital_status.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500',attrs:{"name":"marital_status","autocomplete":"marital_status"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "marital_status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":""},domProps:{"value":null}},[_vm._v("Seleccionar")]),_vm._l((_vm.marital_status),function(status,statusIndex){return _c('option',{key:statusIndex},[_vm._v(_vm._s(status))])})],2)]),_c('div',{staticClass:"w-full mt-1"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.marital_status_text),expression:"form.marital_status_text"}],staticClass:"shadow-sm h-50 mb-4 resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md",attrs:{"placeholder":"Comentarios","rows":"5"},domProps:{"value":(_vm.form.marital_status_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "marital_status_text", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.marital_status.required)?_c('div',{staticClass:"text-sm text-red-600"},[_vm._v(" El Estado Civil es obligatorio ")]):_vm._e()]),_c('div',{staticClass:"col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"},[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"living"}},[_vm._v("¿Con quién vive?")]),_c('div',{staticClass:"mt-1 w-full"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.living),expression:"form.living"}],staticClass:"block border w-full rounded-md shadow-sm sm:text-sm",class:_vm.submitted && _vm.$v.form.living.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500',attrs:{"name":"living","autocomplete":"living"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "living", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":""},domProps:{"value":null}},[_vm._v("Seleccionar")]),_vm._l((_vm.living),function(element,elementIndex){return _c('option',{key:elementIndex},[_vm._v(_vm._s(element))])})],2)]),_c('div',{staticClass:"w-full mt-1"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.living_text),expression:"form.living_text"}],staticClass:"shadow-sm h-50 mb-4 resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md",attrs:{"placeholder":"Comentarios","rows":"5"},domProps:{"value":(_vm.form.living_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "living_text", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.living.required)?_c('div',{staticClass:"text-sm text-red-600"},[_vm._v(" Esta información es obligatoria ")]):_vm._e()]),_c('div',{staticClass:"col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"},[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"educational_level"}},[_vm._v("Nivel de estudios")]),_c('div',{staticClass:"mt-1 w-full"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.educational_level),expression:"form.educational_level"}],staticClass:"block border w-full rounded-md shadow-sm sm:text-sm",class:_vm.submitted && _vm.$v.form.educational_level.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500',attrs:{"name":"educational_level","autocomplete":"educational_level"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "educational_level", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":"","disabled":""},domProps:{"value":null}},[_vm._v("Seleccionar")]),_vm._l((_vm.educational_level),function(element,elementIndex){return _c('option',{key:elementIndex},[_vm._v(_vm._s(element))])})],2)]),_c('div',{staticClass:"w-full mt-1"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.educational_level_text),expression:"form.educational_level_text"}],staticClass:"shadow-sm h-50 mb-4 resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md",attrs:{"placeholder":"Comentarios","rows":"5"},domProps:{"value":(_vm.form.educational_level_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "educational_level_text", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.educational_level.required)?_c('div',{staticClass:"text-sm text-red-600"},[_vm._v(" El nivel educacional es obligatorio ")]):_vm._e()]),_c('div',{staticClass:"col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"},[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"job_occupation"}},[_vm._v("Ocupación laboral")]),_c('div',{staticClass:"mt-1 w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.job_occupation),expression:"form.job_occupation"}],staticClass:"block border w-full rounded-md shadow-sm sm:text-sm",class:_vm.submitted && _vm.$v.form.job_occupation.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500',attrs:{"type":"text","name":"job_occupation","id":"job_occupation","placeholder":"Escribe aquí"},domProps:{"value":(_vm.form.job_occupation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "job_occupation", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.job_occupation.required)?_c('div',{staticClass:"text-sm text-red-600"},[_vm._v(" La ocupación es obligatoria ")]):_vm._e()]),_c('div',{staticClass:"col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"},[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"diseases"}},[_vm._v("Enfermedades")]),_c('div',{staticClass:"mt-1 w-full"},[_c('div',{staticClass:"w-full flex flex-col"},_vm._l((_vm.diseases),function(element,elementIndex){return _c('div',{key:elementIndex,staticClass:"w-full flex justify-between my-2 items-center"},[_c('span',{staticClass:"text-sm text-gray-700 text-left"},[_vm._v(_vm._s(element))]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex items-center mr-4"},[_c('button',{staticClass:"h-8 w-8 rounded-md mr-2 flex justify-center items-center",class:_vm.form.diseases.split(';').includes(element)
                              ? 'bg-green-600'
                              : 'bg-gray-200',attrs:{"type":"button"},on:{"click":function () {
                              _vm.temp = _vm.form.diseases.split(';');
                              _vm.temp.push(element);
                              _vm.form.diseases = _vm.temp.join(';');
                              _vm.diseases_null = _vm.diseases_null.filter(
                                function (e) { return e !== element; }
                              );
                            }}},[(_vm.form.diseases.split(';').includes(element))?_c('svg',{staticClass:"w-4 h-4 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M4.5 12.75l6 6 9-13.5"}})]):_vm._e()]),_c('span',{staticClass:"text-gray-600"},[_vm._v("Si")])]),_c('div',{staticClass:"flex items-center mr-4"},[_c('button',{staticClass:"h-8 w-8 rounded-md mr-2 flex justify-center items-center",class:!_vm.form.diseases.split(';').includes(element) &&
                            !_vm.diseases_null.includes(element)
                              ? 'bg-green-600'
                              : 'bg-gray-200',attrs:{"type":"button"},on:{"click":function () {
                              _vm.form.diseases = _vm.form.diseases
                                .split(';')
                                .filter(function (e) { return e !== element; })
                                .join(';');
                              _vm.diseases_null = _vm.diseases_null.filter(
                                function (e) { return e !== element; }
                              );
                            }}},[(
                              !_vm.form.diseases.split(';').includes(element) &&
                                !_vm.diseases_null.includes(element)
                            )?_c('svg',{staticClass:"w-4 h-4 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M4.5 12.75l6 6 9-13.5"}})]):_vm._e()]),_c('span',{staticClass:"text-gray-600"},[_vm._v("No")])])])])}),0)]),_c('div',{staticClass:"w-full mt-1"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.diseases_text),expression:"form.diseases_text"}],staticClass:"shadow-sm h-50 mb-4 resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md",attrs:{"placeholder":"Comentarios","rows":"5"},domProps:{"value":(_vm.form.diseases_text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "diseases_text", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.diseases.required)?_c('div',{staticClass:"text-sm text-red-600"},[_vm._v(" Esta información es obligatoria ")]):_vm._e()]),_c('div',{staticClass:"col-span-6 mb-4 md:col-span-3 flex flex-col justify-center items-start"},[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"specialty"}},[_vm._v("Especificar nombre de médico que lo deriva y su especialidad")]),_c('div',{staticClass:"mt-1 w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.specialty),expression:"form.specialty"}],staticClass:"block border w-full rounded-md shadow-sm sm:text-sm",class:_vm.submitted && _vm.$v.form.specialty.$error
                      ? 'border-red-600'
                      : 'border-gray-300 focus:border-green-500 focus:ring-green-500',attrs:{"type":"text","name":"specialty","id":"specialty","placeholder":"Escribe aquí"},domProps:{"value":(_vm.form.specialty)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "specialty", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.form.specialty.required)?_c('div',{staticClass:"text-sm text-red-600"},[_vm._v(" La especialidad es obligatoria ")]):_vm._e()])]),_vm._m(1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"flex flex-col justify-items items-start px-4 sm:px-0"},[_c('h3',{staticClass:"text-lg font-medium leading-6 text-gray-900"},[_vm._v(" Ficha Primera Consulta ")]),_c('p',{staticClass:"w-full text-left mt-1 text-sm text-gray-600"},[_vm._v(" A continuación, encontrará una serie de preguntas de carácter confidencial que su doctor(a) le solicita completar previo a la primera atención agendada. De esta manera, el día de la cita podrán dedicar más tiempo a ahondar en los motivos de su consulta médica. Completar esta ficha no es de carácter obligatorio, pero le aseguramos que mejorará la experiencia de su primera consulta. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-b-md"},[_c('button',{staticClass:"inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])])}]

export { render, staticRenderFns }